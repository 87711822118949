.badge-filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
}

.badge-filter-container .badge-filter-icon {
    margin-left: auto;
    margin-right: 15px;
}

.badge-filter-contents {
    padding: 10px;
}

.badge-page .badge-filter-chip {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.badge-count-container {
    margin-left: 15px;
}