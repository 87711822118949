.shield-path {
    stroke: white;
    stroke-width: 20px;
}

.count-wrapper {
    text-align: center;
    line-height: 40px;
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 800;
    color: white;
}
