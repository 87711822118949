.trophy-image {
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
}

.score {
    margin-left: auto;
    margin-right: 0;
}

.gc-score {
    margin-left: 20px;
}
