.blob {
    display: inline-block;
    vertical-align: middle;
    margin: 10px;
    height: 20px;
    width: 20px;
    border-radius: 50%;

}

.blob.started {
  background-color: rgba(255, 177, 66, 1);
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.blob.completed {
  background-color: rgb(14, 177, 2);
  box-shadow: 0 0 0 0 rgba(14, 177, 2 1);
}

@keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}