.footer {
    padding-top: 25px;
}

.copyright {
    padding-top: 10px;
}

.footerIcon {
    margin-right: 10px;
}
