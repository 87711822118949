.season-number {
    height: 48px;
    width: 48px;
    position: absolute;
    top: 0;
    text-align:center;
    line-height:48px;
    font-size:32px;
    color: #222831;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}