.badge-filter-controls .badge-filter-input { 
    margin-bottom: 20px;
}

.badge-filter-controls {
    margin: 10px;
}

.badge-filter-button-container {
    display: flex;
}

.badge-filter-button {
    flex-grow: 1
}

.badge-filter-button:first-child {
    margin-right: 20px;
}