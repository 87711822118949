.badge {
    margin-right: 10px;
}

.striped tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.04);
}

.striped tr:last-child td,
tr:last-child th {
    border: 0;
}
