.google-button {
    margin-top: 30px;
}

.facebook-button {
    margin-top: 15px;
}

.facebook-button button {
    min-height: 40px;
    width: 100%;
    text-transform: none;
}

.link-text {
    margin-top: 30px;
}