.edit-wrapper:hover .upload-button {
    filter: brightness(30%);
}

.edit-wrapper:hover .edit-icon {
    display: inline-block !important;
}

.edit-wrapper {
    position: relative;
    width: fit-content;
    height: fit-content;
}

.edit-icon {
    position: absolute;
    top: 36px;
    left: 36px;
    color: white;
    cursor: pointer;
    display: none !important;
}


