.player-rect:hover {
    fill: #222;
}

@media(min-width: 900px) {
    .consolation {
        margin-top: 20px;
        margin-bottom: -100px;
        margin-right: -50px;
    }
}