.results {
    margin-bottom: 50px;
}

.active-item {
    border-left: 2px solid #1870f0;
    cursor: pointer;
}

.list-item {
    border-left: 2px solid #e0e0e6;
    cursor: pointer;
}

.active-item .list-item-text {
    color: '#15141a';
}

.active-item .list-item-text span {
    font-weight: 600;
}

.list-item .list-item-text {
    color: '#5b5b66';
}
