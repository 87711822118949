html {
    scroll-behavior: smooth;
}

.main-container {
    min-height: 300px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#ptr-parent, #ptr-container {
    overflow: visible !important;
}