.badge {
    margin-right: 15px;
}

.striped tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.04);
}

.striped tr:last-child td,
tr:last-child th {
    border: 0;
}

.cut-line { 
    text-align: center !important;
    font-weight: 800 !important;
    color: white !important;
    border: 0;
    background-color: rgba(0, 0, 0, 0.57) !important;
}

.dash { 
    width: 35%;
    display: inline-block;
    border-top: 3px dashed white;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: middle;
}
