.table-title {
    padding: 10px;
}

.game-table {
    margin-bottom: 20px;
}

.game-item {
    margin-left: auto !important;
    margin-right: auto !important;
}

.table-head {
    background-color: #cfd8dc;
}
